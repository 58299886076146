import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function Header() {
  return (
    <Navbar expand="lg" sticky="top" className="navbar-custom">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar navbar-custom">
          <Nav.Link href="#Home">Home</Nav.Link>
          <Nav.Link href="#Services">Our Services</Nav.Link>
          <Nav.Link href="#About">About</Nav.Link>
          <Nav.Link href="#Team">Founders</Nav.Link>
          <Nav.Link href="#Work">Our Work</Nav.Link>
          <Nav.Link href="#Contact">Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
