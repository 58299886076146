import { BsInstagram, BsFacebook } from "react-icons/bs";

function Contact() {
  return (
    <section class="boxes mb-4" id="Contact">
      <h2 className="title">
        <b>Contact us</b>
      </h2>
      <p class="text-center w-responsive mx-auto mb-5">
        Do you have any questions? Please do not hesitate to contact us
        directly. <br /> Our team will come back as soon as possible to help
        you.
      </p>

      <div class="row">
        <div class="col-md-9 mb-md-0 mb-5">
          <form name="contact" method="post">
            <input type="hidden" name="form-name" value="contact" />
            <div class="row">
              <div class="col-md-6">
                <div class="md-form mb-0">
                  <label for="name" class="">
                    Your name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="md-form mb-0">
                  <label for="email" class="">
                    Your email:
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <div className="md-form pb-2">
                  <label for="message">Your message:</label>
                  <textarea
                    type="text"
                    id="message"
                    name="message"
                    rows="2"
                    class="form-control md-textarea"
                    required
                  ></textarea>
                </div>
              </div>
            </div>
            <div>
              <button type="submit" className="btn btn-primary">
                Send
              </button>
            </div>
          </form>
          <div class="status"></div>
        </div>
        <div class="col-md-3 text-center">
          <ul class="list-unstyled">
            <li>
              <p className="link">Reigate, Surrey</p>
            </li>

            <li>
              <p className="link">Nick - 07734256756</p>
            </li>
            <li>
              <p className="link">Gus - 07860677240</p>
            </li>
            <li>
              <p className="link">info@EliaGardens.com</p>
            </li>
          </ul>
        </div>
      </div>
      <br />
      <div className="socials">
        <a
          className="link"
          href="https://www.facebook.com/profile.php?id=100086492328098"
          target="_blank"
          rel="noreferrer"
        >
          <BsFacebook className="small-icon"></BsFacebook>
        </a>
        <a
          className="link"
          href="https://www.instagram.com/elia.gardens"
          target="_blank"
          rel="noreferrer"
        >
          <BsInstagram className="small-icon"></BsInstagram>
        </a>
      </div>
    </section>
  );
}

export default Contact;
