import "./App.css";
import React from "react";
import clearLogo from "./images/SmallClearLogo.png";
import Header from "./components/Header";
import Contact from "./components/contact";
import Nick from "./images/Nick.jpeg";
import Gus from "./images/gusWebsite.jpeg";
import Garden1 from "./images/garden1.jpg";
import Garden2 from "./images/garden2.jpg";
import Garden3 from "./images/garden3.jpg";
import Garden4 from "./images/garden4.jpg";
import Garden5 from "./images/garden5.jpg";
import Garden6 from "./images/garden6.jpg";
import Garden7 from "./images/garden7.jpg";
import Garden8 from "./images/garden8.jpg";
import Garden9 from "./images/garden9.jpg";
import { GiOakLeaf, GiWheelbarrow, GiShears } from "react-icons/gi";

// change the first column to have 2 columns inside of it

function App() {
  return (
    <>
      <Header></Header>
      <section className="background"></section>
      <div className="content" id="Home">
        <img src={clearLogo} className="foreground" alt="background"></img>
        <h1 class="display-7 slogan">CUSTODIANS OF YOUR GREEN SPACE</h1>
      </div>
      <section className="boxes" id="Services">
        <h2 className="title">
          <b>Our Services</b>
        </h2>
        <p className="pb-5">
          We offer a wide range of services to suit our varied client base.
          <br />
          If you require maintenance that isn't on this list, please get in
          touch and we'll do our best to help!
        </p>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm">
              <GiShears className="icon" />
              <h2 className="header pb-2">Private Maintenence</h2>
              <ul className="list-unstyled">
                <li> Mowing</li>
                <li> Hedge cutting</li>
                <li> Bed maintenance</li>
                <li> Planting</li>
                <li> Composting</li>
                <li> Lawn renovation</li>
                <li> Garden clearance</li>
                <li> Waste disposal</li>
                <li> Pruning</li>
              </ul>
            </div>
            <div className="col-sm pb-5">
              <GiWheelbarrow className="icon" />
              <h2 className="header pb-2">Landscaping and Design</h2>
              <ul className="list-unstyled">
                <li> Fencing</li>
                <li> Compost container production</li>
                <li> Custom planters</li>
                <li> Outdoor carpentry</li>
                <li> Bespoke projects and design</li>
              </ul>
            </div>
            <div className="col-sm pb-5">
              <GiOakLeaf className="icon" />
              <h2 className="header pb-2">Commercial Maintenence</h2>
              <ul className="list-unstyled">
                <li> Bespoke service catered to business needs</li>
                <li> For offices, pubs, restaurants</li>
                <li> Other commercial services available on request</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="boxes" id="About">
        <h2 className="title">
          <b>About</b>
        </h2>
        Welcome to Elia Gardens, we are a hardworking team of professional
        gardeners offering bespoke services to both private and commercial
        customers. With a passion for creating beautiful outdoor spaces, we
        allow our clients to enjoy them to their full potential all year round.
        From the smallest seed to the biggest tree you can trust us as the
        custodians of your garden.
        <br />
        <br />
        We are based in Reigate, currently working throughout Surrey, East
        Sussex and Kent.
        <br />
        <br />
        <br /> <h2 className="header pb-2">Medium to Large Private Gardens</h2>
        Medium gardens start at 2 hours minimum per visit, weekly or biweekly.
        Large gardens start at a half day every week. We will maintain every
        inch of your garden to keep it looking perfect all year round.
        <br />
        <br /> <h2 className="header pb-2">Private Estates</h2>
        Private estates require a minimum of a day a week. We can deal with
        every aspect of grounds maintenance. If you want a home in the
        countryside and are worried about keeping the grounds tidy then rest
        assured we have the team and the tools to take care of everything so you
        can enjoy it.
        <br />
        <br /> <h2 className="header pb-2">Commercial Grounds Maintenance</h2>
        We can maintain private business premises that contain any green areas.
        We will tailor the frequency and length of our visits to suit the
        business's needs. Please enquire for more details.
        <br />
        <br /> <h2 className="header pb-2">Landscaping and Design</h2>
        We also offer landscaping and garden design work should that be what you
        are after. We work for existing maintenance clients, improving their
        green spaces and one-off clients for larger jobs. Please contact us with
        details of what you are after to arrange a quote.
        <br />
        <br />
        <br />
        Please don't hesitate to contact us if you require anything that has not
        been listed and we will do our upmost to find a way to accomplish what
        it is you require.
      </section>
      <section className="boxes" id="Team">
        <h2 className="title pb-5">
          <b>Founders of Elia Gardens</b>
        </h2>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm pb-5">
              <img src={Nick} className="profile" alt="Nick"></img>
              <h2 className="pt-5">Nick</h2>
            </div>
            <div className="col-sm pb-5">
              <img src={Gus} className="profile" alt="Gus"></img>
              <h2 className="pt-5">Gus</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="imageBox" id="Work">
        <h2 className="title pb-4">
          <b>Our Work</b>
        </h2>
        <div className="row g-0">
          <div className="col-sm">
            <img src={Garden1} className="images" alt="garden1"></img>
            <img src={Garden2} className="images" alt="garden2"></img>
            <img src={Garden3} className="images" alt="garden3"></img>
          </div>
          <div className="col-sm">
            <img src={Garden4} className="images" alt="garden4"></img>
            <img src={Garden5} className="images" alt="garden5"></img>
            <img src={Garden6} className="images" alt="garden6"></img>
          </div>
          <div className="col-sm">
            <img src={Garden7} className="images" alt="garden7"></img>
            <img src={Garden8} className="images" alt="garden8"></img>
            <img src={Garden9} className="images" alt="garden9"></img>
          </div>
        </div>
      </section>
      <Contact />
    </>
  );
}

export default App;
